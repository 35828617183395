import "bootstrap/dist/js/bootstrap";
import Swiper, { Autoplay, Navigation } from "swiper";

Swiper.use([Autoplay, Navigation]);

(() => {
  const frontpageSliderContainer = document.querySelector('.frontpage-header__slider__container');

  if ( typeof frontpageSliderContainer != 'undefined' && frontpageSliderContainer != null ) {
    new Swiper(frontpageSliderContainer, {
      slidesPerView: 1,
      speed: 1200,
      autoplay: {
        delay: 12000
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      loop: true
    })
  }
})();

(() => {
  const offerSlider = document.querySelector('.offer-slider');

  if ( typeof offerSlider != 'undefined' && offerSlider != null ) {
    new Swiper(offerSlider, {
      slidesPerView: 1,
      loop: true,
      speed: 1200,
      autoplay: {
        delay: 5000
      }
    });
  }
})();

(() => {
  const partnersSlider = document.querySelector('.partners__slider');

  if ( typeof partnersSlider != 'undefined' && partnersSlider != null ) {
    new Swiper(partnersSlider, {
      loop: true,
      speed: 800,
      autoplay: {
        delay: 2000
      },
      slidesPerView: 1,
      spaceBetween: 40,
      breakpoints: {
        992: {
          slidesPerView: 6
        },
        768: {
          slidesPerView: 5
        },
        680: {
          slidesPerView: 4
        },
        580: {
          slidesPerView: 3
        },
        480: {
          slidesPerView: 2
        }
      }
    });
  }
})();

(() => {
  const mobileNav = document.querySelector('.mobile-nav');

  if ( typeof mobileNav != 'undefined' && mobileNav != null ) {
    const openButton = document.querySelector('.js-open-menu');
    const closeButton = document.querySelector('.js-close-menu');
    const toggleClass = 'visible';

    openButton.addEventListener('click', () => {
      if ( !mobileNav.classList.contains(toggleClass) ) {
        mobileNav.classList.add(toggleClass);
      } else {
        mobileNav.classList.remove(toggleClass);
      }
    });

    closeButton.addEventListener('click', () => {
      mobileNav.classList.remove(toggleClass);
    });
  }
})();